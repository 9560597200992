import { Component, Injector, OnInit, AfterViewInit, Inject, Optional, InjectionToken, ViewEncapsulation } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { PlanService, SettingService } from '../../../services/project.service';
import { CommonLookupServiceProxy, PlanOutput, SettingOutput } from '@shared/service-proxies/service-proxies';
import { DomSanitizer } from '@angular/platform-browser';
import { getAllISOCodes, Currency } from "iso-country-currency";
import { HttpClient } from '@angular/common/http';
export const API_STRIPE_KEY_SubscribePlans_BS = new InjectionToken<string>('API_STRIPE_KEY_SubscribePlans_BS');
@Component({
    selector: 'app-bscalcsubscriptionplans',
    templateUrl: './bscalc.subscriptionplans.component.html',
    animations: [appModuleAnimation()]
    , styles: ['.tooltipc {position: relative;display: inline-block;}.tooltipc .tooltiptextc { font-weight:400; line-height: 1.5;visibility: hidden;width: 200px;background-color: #000;color: #fff;text-align: center; border-radius: 6px; padding: 5px 10px; position: absolute; z-index: 1;top: 100%;left: 50%;margin-left: -100px;opacity: 0;transition: opacity 0.3s;font-size: .875rem;} .tooltipc .tooltiptextc::after {content: "";position: absolute;bottom: 100%;left: 50%;margin-left: -5px;border-width: 5px;border-style: solid;border-color: transparent transparent #000 transparent;}.tooltipc:hover .tooltiptextc {visibility: visible;opacity: 1;color:#fff;}'],
    encapsulation: ViewEncapsulation.None
})
export class BSCalcSubscriptionPlanComponent extends AppComponentBase implements OnInit, AfterViewInit {
    public planOutput: PlanOutput[] = [];
    public annualPlanOutput: PlanOutput[] = [];
    public monthlyPlanOutput: PlanOutput[] = [];
    private calcStandardId: number = 0;
    public settingOutput: SettingOutput = new SettingOutput();
    public subscribePlanResult: string = "";
    public successFlag: boolean = false;
    public errorFlag: boolean = false;
    public plan: PlanOutput = new PlanOutput();
    public stripe: any;
    private stripeKeySubscribePlansBS: string;

    public Countries: Currency[] = getAllISOCodes();
    public selectedCountry: string = 'GB';
    public selectedCountryObj: Currency = this.Countries.find(m => m.iso == this.selectedCountry);
    public currencyData: any;
    public btnDisabled: boolean = false;

    constructor(injector: Injector,
        private _planService: PlanService,
        private _settingService: SettingService,
        protected html_sanitizer: DomSanitizer,
        private _commonLookupServiceProxy: CommonLookupServiceProxy,
        private http: HttpClient,
        @Optional() @Inject(API_STRIPE_KEY_SubscribePlans_BS) stripeKeySubscribePlansBS?: string) {
        super(injector);
        (window as any).bsSub = this;
        this.stripeKeySubscribePlansBS = stripeKeySubscribePlansBS ? stripeKeySubscribePlansBS : "";
        this.loadScripts();
        this.getCurrentCountry();
        this.getCurrentCurrencyRate();
    }

    getCurrentCountry() {
        this._commonLookupServiceProxy.getIpInfo().subscribe((response) => {
            if (response && response.isSuccess) {
                if (response.data && response.data.country) {
                    this.selectedCountry = response.data.country;
                    this.selectedCountryObj = this.Countries.find(m => m.iso == this.selectedCountry);
                }
            }
            this.sortCountries();
        });
    }

    sortCountries(): void {
        this.Countries = [...this.Countries.sort((a, b) => this._commonLookupServiceProxy.compareCountries(a, b, this.selectedCountryObj.countryName))];
    }

    getCurrentCurrencyRate() {
        this._commonLookupServiceProxy.getCurrencyInfo("GBP").subscribe((response) => {
            if (response && response.isSuccess && response.data) {
                this.currencyData = JSON.parse(response.data);
            }
        });
    }

    ngOnInit(): void {
        this.getcablesettings();
        $(".subscription-button").attr("style", "display: none !important");
    }
    ngAfterViewInit() {
        var monthly = document.getElementById("pricing-row-monthly");
        monthly.style.display = "none";

        stripe = Stripe(this.stripeKeySubscribePlansBS);
        //stripe = Stripe("pk_live_TORyoEKV6uQJYXP2vEfDNuR0002WqtLQzL");
        //stripe = Stripe("pk_test_bwmKrezGVYw1S4euBY21MW9R00ZxjTd5Ft");
        $(document).on('click', '#emailid-as-username', (e: any) => {

            if ($('#emailid-as-username').prop('checked')) {
                if ($('#unregemailid').val().toString().trim().length > 0)
                    $('#userid').val($('#unregemailid').val());
            }
            else { $('#userid').val(''); }
        });
        //     var monthly= document.getElementById("pricing-row-monthly");
        //    monthly.style.display = "none";
    }
    loadScripts() {
        const dynamicScripts = [
            'assets/anonymouspage/js/jquery-ui.min.js',
            //'assets/anonymouspage/js/plugins.js',
            //'assets/anonymouspage/js/owl.carousel.js',
            'assets/anonymouspage/js/jquery.accordion.js',
            'assets/anonymouspage/js/custom.js',
            'https://unpkg.com/ionicons@5.0.0/dist/ionicons.js',

        ];
        for (let i = 0; i < dynamicScripts.length; i++) {
            const node = document.createElement('script');
            node.src = dynamicScripts[i];
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
            document.getElementsByTagName('body')[0].appendChild(node);
        }

        const dynamicCss = [
            'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600%7COpen+Sans:400%7CVarela+Round',
            'assets/anonymouspage/css/jquery.accordion.css',
            'assets/anonymouspage/css/ionicons.min.css',
            'assets/anonymouspage/css/styleinside.css',

        ];
        for (let i = 0; i < dynamicCss.length; i++) {
            const node = document.createElement('link');
            node.href = dynamicCss[i];
            node.rel = 'stylesheet';
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }

    ngOnDestroy() {
        $('link[href="assets/anonymouspage/css/styleinside.css"]').remove();
        $(".subscription-button").attr("style", "display: block");
    }
    getcablesettings() {
        this._settingService.getSettings().subscribe(result => {
            if (result.id !== undefined && result.id !== null) {
                this.settingOutput = result;
                if (this.settingOutput.standards_CableSizing == 0 || this.settingOutput.standards_CableSizing == 1)
                    this.calcStandardId = 100;
                else if (this.settingOutput.standards_CableSizing == 2)
                    this.calcStandardId = 102;
                this.GetPlans(this.calcStandardId);
            }
        }, error => {
        });
    }
    sliderSwitchClick() {
        // var x = document.getElementById("annual-price-base")
        // var y = document.getElementById("annual-price-full");
        // var z = document.getElementById("annual-price-pro");
        // var a = document.getElementById("monthly-price-base")
        // var b = document.getElementById("monthly-price-full");
        // var c = document.getElementById("monthly-price-pro");
        var annual = document.getElementById("pricing-row-annual");
        var monthly = document.getElementById("pricing-row-monthly");

        if (annual.style.display === "none") {
            annual.style.display = "flex";
            monthly.style.display = "none";
        }
        else {
            annual.style.display = "none";
            monthly.style.display = "flex";
        }
        // if (x.style.display === "none") {
        // 	x.style.display = "block";
        // 	y.style.display = "block";
        // 	z.style.display = "block";
        // 	a.style.display = "none";
        // 	b.style.display = "none";
        // 	c.style.display = "none";
        // } else {
        // 	x.style.display = "none";
        // 	y.style.display = "none";
        // 	z.style.display = "none";
        // 	a.style.display = "block";
        // 	b.style.display = "block";
        // 	c.style.display = "block";
        // }
    }
    GetPlans(standardid: number): void {
        this._planService.getPlans(standardid).subscribe(result => {
            if (result.items.length > 0) {

                this.planOutput = result.items;
                this.annualPlanOutput = this.planOutput.filter(x => x.planType == 1).sort(x => x.id);
                this.monthlyPlanOutput = this.planOutput.filter(x => x.planType == 2).sort(x => x.id);
            }
            else {
                this.planOutput = [];
                this.annualPlanOutput = [];
                this.monthlyPlanOutput = [];
            }
        });
    }
    sameAsHtml(html_content) {
        var a = this.html_sanitizer.bypassSecurityTrustHtml(html_content);
        return a;
    }

    BuySubscription(planId: number): void {
        this.btnDisabled = true;
        this._planService.createPlan(planId).subscribe(result => {
            this.errorFlag = (result.split('|')[0] === 'E');

            this.successFlag = (result.split('|')[0] === 'S');

            this.subscribePlanResult = result.split('|')[1];

            if (this.successFlag) {
                this.subscribePlanResult = 'Redirecting to checkout page...';

                stripe.redirectToCheckout({
                    sessionId: result.split('|')[1],

                })
                    .then((result) => {
                        // If `redirectToCheckout` fails due to a browser or network
                        // error, display the localized error message to your customer
                        // using `result.error.message`.
                    });
            }
        }, () => { }, () => {
            this.btnDisabled = false;
        });
    }

    onddlCountryChange(data) {
        this.selectedCountryObj = this.Countries.find(m => m.iso == data.iso);
    }

    getCurrencyForPrice(amount) {
        let price = amount;

        if (this.currencyData && this.currencyData.data && this.selectedCountryObj) {
            try {
                let rate = this.currencyData.data[this.selectedCountryObj.currency];
                if (rate) {
                    return Math.round(parseInt(amount) * parseFloat(rate.value));
                }
            }
            catch { return price; }
        }

        return price;
    }
}
